/* page content */
.page-content {
    display: flex;
    flex-direction: column;
    padding: 60px;
    margin-left: -20px;
    margin-right: -20px;
}

/* Heading */
h1 {
    font-size: 36px;
    font-weight: 600;
    margin: 0;
}

/* Subheading */
h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

/* Paragraph */
p {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 16px;
    margin-bottom: 0;
}

/* Links */
a {
    color: #0366d6;
    text-decoration: none;
    font-weight: 600;
}

    a:hover {
        text-decoration: underline;
    }
