.projects {
    margin: 0 auto;
    animation: fade 0.2s ease-in-out forwards;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.projects h1 {
    margin-bottom: 20px;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 350px);
    grid-gap: 20px;
    margin-top: 30px;
}

@media screen and (max-width: 992px) {
    .projects-grid {
        grid-template-columns: repeat(2, 350px);
    }
}

@media screen and (max-width: 768px) {
    .projects-grid {
        grid-template-columns: 350px;
    }
}

.project-box {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.project-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-box img {
    display: block;
    width: 100%;
    height: auto;
}

.project-details {
    padding: 20px;
}

.project-details h2 {
    margin: 0 0 10px 0;
    font-size: 1.2rem;
}

.project-details p {
    margin: 0 0 20px 0;
    font-size: 1rem;
    line-height: 1.5;
}

.project-details a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 3px;
}

.project-details a:hover {
    background-color: #0062cc;
    text-decoration: none;
}

.project-details a:not(:last-child) {
    margin-right: 8px;
}

.project-details a + a {
    margin-left: 0px;
}

.projects h1 {
    margin-bottom: 30px;
}

.projects-grid {
    margin-top: 40px;
}
