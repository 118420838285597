.blog {
    max-width: 800px;
    margin: 0 auto;
    animation: fade 0.2s ease-in-out forwards;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.blog h1 {
    margin-bottom: 40px;
}

.blog-post {
    display: block;
    text-decoration: none;
    margin-bottom: 40px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.blog-post:last-child {
    margin-bottom: 0px;
}

.blog-post:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-post h2 {
    font-size: 24px;
    margin-bottom: 10px;
    padding: 15px;
    background-color: #007bff;
    color: #fff;
}

.blog-post p {
    font-size: 16px;
    line-height: 1.5;
    margin: 20px;
    color: black;
    font-weight: normal;
}

.blog-post-date {
    display: block;
    font-size: 14px;
    color: #999;
    margin: 20px;
}

.blog-post + .blog-post {
    margin-top: 20px;
}

.blog-post:hover {
    text-decoration: none;
}
